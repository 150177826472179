<template>
  <div class="container">
    <div class="row d-flex justify-content-around align-items-center">
      <div class="col-3 text-left">
        <img class="w-75" src="/assets/images/logo.svg" />
      </div>
      <div class="col-6">
        <h2 class="header-title mb-0 mt-3 text-center text-uppercase">Reporte de Audiencia</h2>
      </div>
      <div class="col-3 text-right">
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-5">
        <table class="table">
            <tbody>
              <tr>
                <td class="font-weight-bold"><h5>Fecha de Audiencia:</h5></td>
                <td>{{ audience.date | moment('DD-MM-YYYY') }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Cliente:</h5></td>
                <td>
                  <span v-if="audience.client" class="text-left text-capitalize">
                    <span v-if="audience.client.type_id === 2">
                      {{ audience.client.commercial_name | uppercase }}
                    </span>
                    <span v-else class="text-capitalize">
                      {{ audience.client.first_name | uppercase }}
                      {{ audience.client.last_name | uppercase }}
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Abogado:</h5></td>
                <td class="text-capitalize">{{ audience.record.lawyer.first_name | uppercase }} {{ audience.record.lawyer.last_name | uppercase }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Contrario:</h5></td>
                <td class="text-capitalize">{{ audience.record.against_to | uppercase }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Abogado:</h5></td>
                <td class="text-capitalize">{{ audience.record.defense_lawyer | uppercase }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Tribunal:</h5></td>
                <td>
                  <span class="text-capitalize" v-if="audience.record.court">
                    {{ audience.record.court.name | uppercase }}
                  </span>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <div class="col-12 mt-3 mb-3">
        <h3 class="mt-5 text-center">Planteamiento de las Partes</h3>
        <div v-for="(report, index) in audience_notes" :key="index" class="my-4 audience_notes_container px-3 py-2">
          <div v-for="(item, index) in report" class="audience_notes mb-4" :key="index">
              <div class="font-weight-bold mb-2 py-2 mb-2" v-if="item.type === 1">Cliente: </div>
              <div class="font-weight-bold mb-2 py-2 mb-2" v-else-if="item.type === 2">Contraparte: </div>
              <div class="font-weight-bold mb-2 py-2 mb-2" v-else-if="item.type === 3">Tribunal: </div>
              <span class="font-weight-normal">{{ item.details | uppercase }}</span>
              <hr>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center align-items-center flex-column">
        <button type="button" onclick="window.print()" href="#" class="d-print-none btn btn-info d-block">Imprimir</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AgendaAudienceReport',
  props: ['id'],
  data () {
    return {
      audience: {}
    }
  },
  methods: {
    ...mapActions({
      fetchEvent: 'Agenda/fetchEvent'
    })
  },
  computed: {
    audience_notes () {
      return this._.chunk(this._.toArray(this.audience.audience_notes), 3)
    },
    court_notes () {
      return this.audience.audience_notes.filter(function (row) {
        if (row.type === 3) {
          return row
        }
      })
    },
    client_notes () {
      return this.audience.audience_notes.filter(function (row) {
        if (row.type < 3) {
          return row
        }
      })
    }
  },
  mounted () {
    this.fetchEvent({
      id: this.id
    })
      .then(response => {
        this.audience = response.data
      })
      .catch(() => { alert('Un Error ha ocurrido') })
  }
}
</script>
